import Button from '@material-ui/core/Button';
import React, { ReactNode } from 'react';
import { QuickReferenceIcon } from './QuickReferenceIcon';

interface Clinic {
    __typename: string;
    id: string;
    code: string;
    email: string | null;
    name: string;
    hasSoboCart: boolean;
}

/**
 * A mapping of clinic names to their corresponding documentation links.
 * Keys are the lowercase version of each clinic name to enable case-insensitive lookups.
 */
const clinicLinksByName: Record<string, string> = {
    "carrell clinic": "https://mend-labs.atlassian.net/wiki/spaces/UPCARE/pages/45088769",
    "toc tulsa": "https://mend-labs.atlassian.net/wiki/spaces/UPCARE/pages/49709057",
    "alaska fracture & orthopedic clinic": "https://mend-labs.atlassian.net/wiki/spaces/UPCARE/pages/274989057",
    "saos": "https://mend-labs.atlassian.net/wiki/spaces/UPCARE/pages/66256948",
    "idaho sports and spine": "https://mend-labs.atlassian.net/wiki/spaces/UPCARE/pages/96403479",
    "genesee orthopedics": "https://mend-labs.atlassian.net/wiki/spaces/UPCARE/pages/193134604",
    "ojri": "https://mend-labs.atlassian.net/wiki/spaces/UPCARE/pages/325582850"
};

/**
 * Returns the documentation link for the provided clinic name, ignoring case.
 *
 * @param {string} name - The clinic's name.
 * @returns {string | null} The link to the clinic's documentation page, or null if not found.
 */
function getClinicLinkByName(name: string): string | null {
    const lowerName = name.toLowerCase().trim();
    return clinicLinksByName[lowerName] || null;
}

interface ClinicLinkProps {
    clinic: Clinic;
    className: string
}

/**
 * A React component that displays a link to a clinic's documentation page based on the clinic's name.
 * The link content is passed as children. If no link is available for the provided clinic name,
 * it displays a fallback message.
 *
 * @param {ClinicLinkProps} props - The component props.
 * @param {Clinic} props.clinic - The clinic object.
 * @returns {JSX.Element} The JSX element containing the clinic link or a fallback message.
 */
const ClinicLink: React.FC<ClinicLinkProps> = ({ clinic, className }) => {
    const link = getClinicLinkByName(clinic.name);

    const buttonContent = <Button className={className} disabled={!link} >
        <QuickReferenceIcon />
    </Button>

    if (!link) {
        return buttonContent
    }

    return (
        <a href={link} target="_blank" rel="noopener noreferrer">
            {buttonContent}
        </a>
    );
};

export default ClinicLink;
