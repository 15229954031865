/* tslint:enable:no-any */
/**
 *
 * ========================================
 * *********** IMPORTANT ******************
 * ========================================
 * Supports Shop On Behalf Of
 * This functionality is outdated and required modification
 * It was developed for the B2B site (PRS) using Redux and Saga
 * Since then we moved to GraphQL / Apollo.
 * The component must be updated in order to work with new data access
 */
import React, { useEffect, useReducer, useContext } from 'react';

import { useHistory } from 'react-router-dom';

// #region Material-UI
import { Button, IconButton, makeStyles, Theme } from '@material-ui/core';
import HospitalIcon from '@material-ui/icons/LocalHospital';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import CloseIcon from '@material-ui/icons/Close';
import { Pagination } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
// #endregion Material-UI
import { FETCH_SESSION } from '../../operations/queries/getSession';
import { useLazyQuery } from '@apollo/client';
import { AccountCard } from '../AccountCard';
import SoboAccountListItem from './SoboAccountListItem';
import KeywordSearch from '../KeywordSearch';
import { GET_LINKED_ACCOUNTS, LinkedAccount } from "../../operations/queries/getLinkedAccounts";
import { SoboActionEnum } from "./soboInterfaces";
import GlobalLoading from 'components/GlobalLoading';
import { soboReducer, initialState } from "./reducer"
import { enterSoboMode, exitSoboMode } from 'operations/mutations/setSoboMode';
import { SiteContext } from 'utils/SiteProvider';
import { messageMutations } from 'operations/mutations/messages';
import { QuickReferenceIcon } from './QuickReferenceIcon';
import ClinicInformationLink from './ClinicInformationLink';

/**
 * Handles displaying sobo list and controlling sobo functionality
 * @param props
 * @constructor
 */
const SoboDrawer: React.FC = () => {
    const PAGE_SIZE = 10;
    const classes = useStyles();
    const site: any = useContext(SiteContext);
    const maxRetry = 5;
    let currentRetry = 0;

    //TODO: Update to generated types  e4cs-312
    const [loadSoboList, {
        called, data, error, loading,
    }] = useLazyQuery<any>(
        GET_LINKED_ACCOUNTS
    );

    const [loadSessionData, { data: sessionData, error: sessionError, loading: sessionLoading }] = useLazyQuery<any>(FETCH_SESSION)
    const { mutate: enterSobo } = enterSoboMode();
    const { mutate: exitSobo } = exitSoboMode();

    const [state, dispatch] = useReducer(soboReducer, initialState);
    const history = useHistory();
    let soboListSelector: LinkedAccount[] = [];

    if (called && data) {
        soboListSelector = data?.soboAccountList?.nodes.slice().sort((item: any, item2: any) => item.hasSoboCart - (item2.hasSoboCart ? item2.hasSoboCart : false)).reverse() || [];
    }

    console.log('soboListSelector', soboListSelector)

    useEffect(() => {
        if (Number(sessionData?.session.isImpersonated)) {
            const sessionUser = sessionData?.session.soboUser;
            if (sessionUser) {
                //TODO: After confirmation, update to generated types  e4cs-312

                const acc: any = {
                    name: `${sessionUser.firstName} ${sessionUser.lastName}`,
                    code: sessionUser.accountId.toString(),
                    email: sessionUser.email,
                    id: sessionUser.accountId.toString(),
                    userId: sessionUser.aspNetUserId,
                    phoneNumber: null
                }
                dispatch({ type: SoboActionEnum.setSelectedAccount, payload: acc })
            }

        }
    }, [sessionData])

    const setPage = (page: number): void => {
        dispatch({ type: SoboActionEnum.setPage, payload: page });
    };

    const setDrawerOpen = (isOpen: boolean): void => {
        dispatch({ type: SoboActionEnum.setDrawerOpen, payload: isOpen });
    };

    useEffect(() => {
        if (state.drawerOpen) {
            loadSoboList({
                variables: {
                    input: {
                        accountLinkType: 'SOBO'
                    }
                }
            });
        }
        setPage(1);
    }, [state.drawerOpen, loadSoboList, state.selectedSoboAccount]);

    useEffect(() => {
        if (data) {
            loadSoboList({
                variables: {
                    input: {
                        accountLinkType: 'SOBO',
                        skip: (state.page - 1) * PAGE_SIZE,
                        search: state.keyword
                    }
                }
            })
        }
        loadSessionData();
    }, [state.page, state.keyword])

    /**
     * dispatches the keyword search to state
     * @param keyword Keyword from the KeywordSearch component to filter account list
     */
    const handleKeywordDispatch = (keyword: string): void => {
        dispatch({ type: SoboActionEnum.changeKeyword, payload: keyword });
    };

    /**
     * Toggles whether the drawer is open / closed
     */
    const toggleDrawer = (): void => setDrawerOpen(!state.drawerOpen);

    /**
     * Gets total number of pages based on page size and length of the list
     */
    const getTotalPages = (): number => Math.ceil((data?.soboAccountList?.pageInfo?.resultsReturned || 1) / PAGE_SIZE);

    /**
     * Renders a placeholder skeleton while the api loads data
     */
    const renderSkeleton = (): JSX.Element => (
        <>
            {[...Array(PAGE_SIZE).keys()].map((key, i: number) => (
                <Skeleton
                    key={i}
                    className={classes.skeleton}
                    variant="rect"
                    width="100%"
                    height="60px"
                />
            ))}
        </>
    );

    /**
     * Changes the page
     * @param event not used
     * @param newPage Page to set local state to
     */
    const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number): void => setPage(newPage);

    /**
     * Sets sobo mode when user clicks on select button on contact card
     * dispatches event to store
     * @param contact contact for sobo mode
     */
    const handleSetSobo = (contact: LinkedAccount): void => {
        // dispatch(setSoboMode(contact.AccountId));
        enterSobo({
            variables: {
                id: contact.id,
                input: {
                    accountLinkType: 'SOBO',
                    skip: (state.page - 1) * PAGE_SIZE,
                    search: state.keyword
                }
            },
        }).then(() => {
            toggleDrawer();
            window.location.href = '/';
        }).catch((e) => {
            messageMutations.addLowAttentionMessage(
                e.message,
                'alert',
                'warning'
            );
            window.location.href = '/';
        });
    };

    /**
     * Shows dialog prompt to confirm exit sobo
     */
    const handleExitSoboRequest = (): void => {
        dispatch({
            type: SoboActionEnum.exitSobo,
            payload: {
                drawerOpen: false,
                showExitDialog: true,
            },
        });
    };

    /**
     * Dispatches event to exit sobo after user clicks confirm
     */
    const handleExitSoboConfirm = (): void => {
        if (state.selectedSoboAccount) {
            exitSobo()
                .then(() => dispatch({ type: SoboActionEnum.exitSoboConfirm }))
                .then(() => history.push('/account'));
        }
        window.location.href = '/';
    };

    /**
     * Hides confirmation dialog
     */
    const handleExitSoboCancel = (): void => {
        dispatch({ type: SoboActionEnum.exitSoboCancel })
    };

    const getShowingStart = (): number => {
        const currentlyShowing = (state.page - 1) * PAGE_SIZE + PAGE_SIZE;

        return data?.soboAccountList?.pageInfo?.resultsReturned || PAGE_SIZE >= 0
            ? soboListSelector.length
            : currentlyShowing > soboListSelector.length
                ? soboListSelector.length
                : currentlyShowing;

    };

    /**
     * This function is called and rendered when sobo mode is inactive
     */
    const renderSoboList = (): JSX.Element => (
        <>
            <KeywordSearch dispatchKeyword={handleKeywordDispatch} />
            <div>
                Showing
                {' '}
                {getShowingStart()}
                {' '}
                of
                {' '}
                {data?.soboAccountList?.pageInfo?.resultsReturned || 0}
            </div>
            <List>
                {soboListSelector && soboListSelector.length
                    ? soboListSelector
                        .map((contact: LinkedAccount) => (
                            <SoboAccountListItem
                                key={contact.id}
                                contact={contact}
                                handleSetSobo={handleSetSobo}
                            />
                        ))
                    : <Typography style={{ textAlign: 'center', fontWeight: 600 }}>Clinic not found</Typography>}
            </List>
            {getTotalPages() > 1 && (
                <Pagination
                    count={getTotalPages()}
                    page={state.page}
                    onChange={handlePageChange}
                    className={classes.pagination}
                    classes={{
                        ul: classes.paginationUl,
                    }}
                />
            )}
        </>
    );

    if (loading || sessionLoading) {
        return <GlobalLoading>Loading Clinics</GlobalLoading>;
    }
    if (error || sessionError) {
        if (currentRetry <= maxRetry) {
            if (error) {
                loadSoboList({
                    variables: {
                        input: {
                            accountLinkType: 'SOBO'
                        }
                    }
                });
            } else if (sessionError) {
                loadSessionData();
            }
            currentRetry += 1;
        } else {
            return (
                <strong>
                    Error loading clinic list: {error.message}
                </strong>
            );
        }
    }

    console.log('state.selectedSoboAccount', state.selectedSoboAccount)

    return (
        <>
            {state?.selectedSoboAccount ? <ClinicInformationLink className={classes.clinicButton} clinic={state?.selectedSoboAccount as any} /> : undefined}

            <Button className={classes.clinicButton} onClick={toggleDrawer}>
                <HospitalIcon className={classes.buttonIcon} />
                {Number(sessionData?.session?.isImpersonated) ? <Typography>
                    {state?.selectedSoboAccount?.name}
                </Typography> : null}
            </Button>
            <SwipeableDrawer
                anchor="right"
                open={state.drawerOpen}
                onOpen={toggleDrawer}
                onClose={toggleDrawer}
                className={classes.menuDrawer}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Typography variant="h6" className={classes.headerText}>
                    {state.selectedSoboAccount ? `Viewing as ${state?.selectedSoboAccount?.name}` : 'Select Clinic'}
                </Typography>
                <IconButton
                    aria-label="Drawer Close"
                    onClick={toggleDrawer}
                    children={<CloseIcon />}
                    size="medium"
                    className={classes.closeIcon}
                />
                {/* If the user has already logged in (I.E. They're already SOBO'd), it will display the SOBO'd Accounts base information (I.E. Account Name and Account Code of the Clinic) */}
                {state.selectedSoboAccount ? (
                    <AccountCard
                        contact={state.selectedSoboAccount}
                        cardActionText="Exit Sobo"
                        cardSecondaryActionText={"View Profile"}
                        handleClick={handleExitSoboRequest}
                        handleActionClick={handleExitSoboRequest}
                        shouldShowPrimaryAction={false}
                        shouldShowSecondaryAction={false}
                    />
                ) : (null)}
                {/* Always render the SOBO list to allow for selection of Clinic when both SOBO'd and un-SOBO'd */}
                {renderSoboList()}
            </SwipeableDrawer>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    clinicButton: {
        backgroundColor: theme.palette.common.white,
        color: '#19354E', // TODO: This needs to be replaced with the theme palette color version when it becomes available.
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(1),
        borderRadius: 4,
        border: '#5F6D7E 1px solid',
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    buttonIcon: {
        marginRight: theme.spacing(1),
    },
    menuDrawer: {
        justifyContent: 'center',
    },
    drawerPaper: {
        minWidth: '500px',
        padding: '1rem',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: 0,
        },
    },
    headerText: {
        textAlign: 'left',
        marginBottom: '2rem',
    },
    pagination: {
        marginTop: 'auto',
        marginBottom: '1rem',
    },
    paginationUl: {
        justifyContent: 'center',
    },
    closeIcon: {
        position: 'absolute',
        top: '5px',
        right: 0,
        width: '50px',
    },
    skeleton: {
        marginTop: '1rem',
    },
    customBadgeColor: {
        backgroundColor: 'lightgreen',
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    accountIcon: {
        marginRight: '1rem',
    },
    soboIcon: {
        cursor: 'pointer',
        margin: theme.spacing(0.5),
        padding: theme.spacing(1, 2),
        boxSizing: 'content-box',
    },
    soboActiveClass: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main,
        borderRadius: 20,
    },
    soboElementWrapper: {
        display: 'flex',
        alignItems: 'center'
    }
}));

export default SoboDrawer;
